// HOOKS
import { Link } from 'wouter'
import { useContext } from 'react'

// CONTEXTS
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'

// Translations
import { useTranslation } from 'react-i18next';


export default function Title() {
  const { title } = useContext(TitleContext)
  const { t, i18n } = useTranslation();

  return (
    <header
      className="flex mt-2 border-b-4 border-[#0b1f92] print:hidden"
      style={{
        background:
          'linear-gradient(-45deg, white 0%, white 80%, #00c8bb 80%, #00c8bb 80.50%, #0b1f92 80.50%, #0b1f92 81%, white 81%, white 81.50%, #00c8bb 81.50%, #00c8bb 100%'
      }}
    >
      <div className="w-full px-1.5 py-1 mx-auto">
        <h1 className="text-xl font-notoExtraBold text-[#0b1f92]">
          {t('submenu_items.orders')}
        </h1>
      </div>
      {title.buttons && (
        <div className="flex flex-row items-center justify-end w-full py-1 pr-2 mx-auto text-right titleRightButton">
          {title.btnSendAll && (
            <button
              type="button"
              className="h-full px-5 ml-2 font-semibold text-blue-600 transition-colors duration-150 bg-blue-100 border-2 border-blue-600 rounded focus:shadow-outline hover:bg-customtechnoform hover:text-white"
              onClick={() => {
                title.handleSendModal()
              }}
            >
              {title.btnSendAllText}
            </button>
          )}

          {title.buttons.map((button) => {
            return button.id === 'btnBack' ? (
              <Link to={button.link} key={button.name}>
                <button
                  type="button"
                  className="h-full px-5 ml-2 font-semibold text-red-600 transition-colors duration-150 bg-red-100 border-2 border-red-600 rounded focus:shadow-outline hover:bg-red-600 hover:text-white"
                >
                  {button.name}
                </button>
              </Link>
            ) : (
              <Link to={button.link} key={button.name}>
                <button
                  type="button"
                  className="h-full px-5 ml-2 font-semibold text-blue-600 transition-colors duration-150 bg-blue-100 border-2 border-blue-600 rounded focus:shadow-outline hover:bg-customtechnoform hover:text-white"
                >
                  {button.name}
                </button>
              </Link>
            )
          })}
        </div>
      )}
    </header>
  )
}
