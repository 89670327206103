import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  i18n.init({
    resources: {
      es: {
        translation: {
          "admin_comp_grid": {
            "username": "Usuario",
            "name": "Nombre"
          },
          "list_table": {
          "num_order": "Nº Pedido",
          "position": "Posición",
          "reference": "Referencia",
          "confection": "Confección",
          "material": "Material",
          "meters": "Metros",
          "length": "Longitud",
          "rods": "Varillas",
          "package": "Paquete",
          "order": "Pedido",
          "date": "Fecha",
          "delivery": "Entrega",
          "no_date": "No hay fecha",
          "estimated": "Estimados",
          "pallets": "Pallets",
          "estimated_pallets": "Cantidad Estimada de Pallets",
          "foreign_production": "Producción Extranjera",
          "foreign": "Extranjera",
          "production": "Producción",
          "yes": "Sí",
          "no": "No"
          },
          "sidebar": {
            "logout": "Cerrar Sesión"
          },
          "menu_items": {
            "logistics": "Logística",
            "contact": "Contactar"
          },
          "submenu_items": {
                "orders": "Pedidos"
          },
          "table_bar": {
            "order_client": "Nº Pedido Cliente",
            "order_techno": "Nº Pedido Techno",
            "position": "Pos.",
            "reference_client": "Referencia Cliente",
            "reference_techno": "Referencia Technoform",
            "confection": "Conf.",
            "material": "Material",
            "meters": "Metros",
            "length": "Long.",
            "rods": "Varillas",
            "package": "Paquete",
            "order_date": "Fecha Pedido",
            "delivery_date": "Fecha Entrega",
            "estimated_pallets": "Pallets Est.",
            "foreign_production": "P. Ext.",
            "pdf_filename": "Pedidos_",
            "info_title": "Sobre esta página",
            "register": "registros",
            "export_excel": "Exportar Excel",
            "export_pdf": "Exportar PDF",
            "info_tooltip": 'En esta página se presentan las líneas de pedidos realizadas en los últimos 24 meses. \n\n' +
            "En el campo 'Conf.'(Confección), las abreviaturas tienen los siguientes significados:" +
            '\n · N: Sin cordón termofusible ' +
            '\n · G: Con cordón termofusible ' +
            '\n · UB: Sin empaquetar ' +
            '\n · L: Marcado láser ' +
            '\n · WD: Draining ' +
            '\n · AF: Adhesive Film ' +
            '\n · PU: Punching ' +
            '\n · CP: Coating Prime ' +
            '\n · AP: Adhesion Prime'
            },
            "orders_list": {
                "orders": "Pedidos"
            },
            "table": {
                "delete": "Borrar"
            }

        }
      },
      pt: {
        translation: {
          "admin_comp_grid": {
            "username": "Usuário",
            "name": "Nome"
          },
          "list_table": {
          "num_order": "Encomenda",
          "position": "Pos.",
          "reference": "Referência",
          "confection": "Conf.",
          "material": "Material",
          "meters": "Metros",
          "length": "Compr.",
          "rods": "Varetas",
          "package": "Pacote",
          "order": "Encomenda",
          "date": "Data",
          "delivery": "Entrega",
          "no_date": "Não há data",
          "estimated": "Estimada",
          "pallets": "Pallets",
          "estimated_pallets": "Pallets Est.",
          "foreign": "Estrangeira",
          "production": "Produção",
          "foreign_production": "Produção Estrangeira",
          "yes": "Sim",
          "no": "Não"
          },
          "sidebar": {
            "logout": "Sair"
          },
          "menu_items": {
            "logistics": "Logística",
            "contact": "Contato"
          },
          "submenu_items": {
                "orders": "Encomendas"
          },
          "table_bar": {
            "order_client": "Nº Pedido Cliente",
            "order_techno": "Nº Pedido Techno",
            "position": "Pos.",
            "reference_client": "Referência Cliente",
            "confection": "Conf.",
            "material": "Material",
            "meters": "Metros",
            "length": "Comp.",
            "rods_package": "Varetas Pacote",
            "order_date": "Data Pedido",
            "delivery_date": "Data Entrega",
            "estimated_pallets": "Pallets Est.",
            "foreign_production": "Prod. Ext.",
            "pdf_filename": "Encomendas_",
            "info_title": "Sobre esta página",
            "register": "registos",
            "export_excel": "Baixar Excel",
            "export_pdf": "Baixar PDF",
            "info_tooltip": "Nesta página, são apresentadas as linhas de encomendas realizadas nos últimos 24 meses.\n\n" +
                "No campo 'Conf.' (Confeção), as abreviações têm os seguintes significados:\n" +
                "• N: sem cordão termo fusível\n" +
                "• G: com cordão termo fusível\n" +
                "• UB: sem empaquetado\n" +
                "• L: marcado laser\n" +
                "• WD: drenagem de água\n" +
                "• AF: Filme adesivo\n" +
                "• PU: punching\n" +
                "• CP: coating prime\n" +
                "• AP: adhesion prime"
            },
            "orders_list": {
                "orders": "Encomendas"
            },
            "table": {
                "delete": "Limpar"
            }
        }
      }
    }
  });
  

export default i18n;
